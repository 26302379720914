function changeHref(channel) {
    return `https://app.adjust.com/1aar87gm?engagement_type=fallback_click&redirect=https%3A%2F%2Fbaxi-zonghe.s3.us-east-2.amazonaws.com%2Fapk%2F${channel}.apk?03220606`
}

function parseQueryString(url) {
    var params = {};
    var queryString = url.substring(url.indexOf('?') + 1);
    var pairs = queryString.split('&');

    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);

        if (key) {
            params[key] = value;
        }
    }

    return params;
}


window.onload = function () {
    // 获取 id 为 "apk" 的 a 标签
    var apkLink = document.getElementById("apk");

    // 获取当前页面的 URL
    var currentUrl = window.location.href;

    // 解析 URL 中的参数
    var urlParams = parseQueryString(currentUrl);
    // console.log(urlParams)
    // 检查 URL 中是否存在 code 参数
    // if (urlParams.hasOwnProperty('code')) {
    var channel = urlParams['code'];
    apkLink.href = changeHref(channel || 'pgbr01');
    // }
};
